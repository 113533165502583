export const contact = {
    number: "8123322141",
    display: "(812) 332-2141",
    email: "summerhouse@renaissancerentals.com"
};
export const propertyId = "summer-house";
export const shortTermPropertyId = "summer-house-short-term"
export const communityAmenities = [
    "Cottage-style architecture",
    "1.8 miles to IU",
    "Fitness Center",
    "Relaxing pool and sundeck",
    "Carefree lounges",
    "Reservable banquet space",
    "Inviting plaza with grill",
    "Covered Bike storage",
    "On-Site Bus Stop",
    "No sort Recycling",
    "Free Parking",
    "Community Park Nearby",
    "24 Hour Emergency Maintenance",
    "Managed by Renaissance Rentals, Bloomington’s most trusted landlord for over 30 years"
]
export const longTerm = {

    furnishedAmenities: [
        "1 bedroom apartments and 2 bedroom apartments & town homes",
        "Cottage-style finishes",
        "All utilities included",
        "Stylishly furnished throughout + sofa bed in living room",
        "Fully equipped kitchen + ice maker, dishwasher & built-in microwave",
        "Shaker-style soft-close cabinetry",
        "HE Front Load Washer and dryer",
        "Built-in Computer desk",
        "LED TV's in living room & bedroom(s)",
        "Walnut-toned Vinyl plank flooring",
        "Brushed nickel hardware",
        "9' ceilings with ceiling fans",
        "USB charging wall outlets",
        "Kitchen bartop",
        "Window seat with hidden storage"
    ],
    unfurnishedAmenities: [
        "1 & 2 bedroom apartments and 2 & 3 bedroom town homes",
        "Cottage-style finishes",
        "All Utilities included in many apartments",
        "Fully equipped kitchen",
        "Washer and dryer in-unit",
        "Shaker-style cabinetry",
        "Walnut-toned Vinyl plank flooring",
        "Ceiling fans",
        "Brushed nickel hardware",
        "Cats Welcome"
    ]
}
